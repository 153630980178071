import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
} from "@mui/material";
import React from "react";
import { Invoice } from "../../components/Invoice";
import { useGetInvoiceByIdSubscription } from "../../__generated__/types";
import { Link } from "gatsby";
import { Print, Send } from "@mui/icons-material";
import { translations } from "../../translations";
import { useAuthorizer } from "@authorizerdev/authorizer-react";
import { adminSubClient } from "../../apollo/client";
import { ApolloProvider } from "@apollo/client";
import { useSnackbar } from "notistack";

const InvoiceByIdPage = ({ params, path }) => {
  const { id } = params;
  const { data, loading, error } = useGetInvoiceByIdSubscription({
    variables: { id },
    client: adminSubClient,
  });
  const { user } = useAuthorizer();
  const [emailLoading, setEmailLoading] = React.useState(false);
  const selected_locale =
    data?.invoice_by_pk?.reservation?.property.user.selected_locale;
  const { enqueueSnackbar } = useSnackbar();
  if (loading) return;
  if (error) return error.message;
  return (
    <ApolloProvider client={adminSubClient}>
      <Container sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
        <Box display={"flex"} sx={{ alignSelf: "flex-end" }}>
          {user && (
            <Button
              variant="outlined"
              endIcon={
                emailLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Send />
                )
              }
              sx={{
                mr: 1,
                mb: 1,
                alignSelf: "flex-end",
                "@media print": {
                  display: "none",
                },
              }}
              disabled={emailLoading}
              onClick={async () => {
                setEmailLoading(true);
                const emailResponse = await fetch("/api/send-invoice", {
                  method: "POST",
                  body: JSON.stringify({
                    id: data?.invoice_by_pk?.reservation?.id,
                    arrival_date:
                      data?.invoice_by_pk?.reservation?.arrival_date,
                    departure_date:
                      data?.invoice_by_pk?.reservation?.departure_date,
                    property_id:
                      data?.invoice_by_pk?.reservation?.property.user
                        .selected_property_id,
                    selected_locale:
                      data?.invoice_by_pk?.reservation?.property.user
                        .selected_locale,
                  }),
                })
                  .then(async (response) => await response.json())
                  .catch(() => ({ data: { success: false } }));
                if (emailResponse.data.success) {
                  enqueueSnackbar("Емайл с фактурата е изпратен успешно", {
                    variant: "success",
                  });
                }
                if (!emailResponse.data.sucess) {
                  enqueueSnackbar("Емайл с фактурата не е изпратен успешно", {
                    variant: "error",
                  });
                }
                setEmailLoading(false);
              }}
            >
              Изпрати
            </Button>
          )}
          <Button
            color="secondary"
            variant="contained"
            endIcon={<Print />}
            sx={{
              mb: 1,
              alignSelf: "flex-end",
              "@media print": {
                display: "none",
              },
            }}
            onClick={() => {
              window.print();
            }}
          >
            {translations[selected_locale].print}
          </Button>
        </Box>

        {data?.invoice_by_pk && (
          <Invoice
            id={data?.invoice_by_pk?.reservation?.id}
            arrival_date={data?.invoice_by_pk?.reservation?.arrival_date}
            departure_date={data?.invoice_by_pk?.reservation?.departure_date}
          />
        )}
        {user && (
          <Link
            to={`/reservations/${data?.invoice_by_pk?.reservation?.id}`}
            style={{ textDecoration: "inherit" }}
          >
            <Card
              sx={{
                mt: 2,
                "@media print": {
                  display: "none",
                },
              }}
            >
              <CardHeader
                title={translations[selected_locale].reservation}
                action={
                  <Button variant="contained" color="secondary">
                    {translations[selected_locale].view}
                  </Button>
                }
              />
            </Card>
          </Link>
        )}
      </Container>
    </ApolloProvider>
  );
};

export default InvoiceByIdPage;
